import { cloudRecordingDescription, localRecordingDescription } from '@tabeeb/modules/whiteboard/constants'
import {
  isAdvancedInviteSettingsEnabled,
  isContentMetadataEnabled,
  showTowerSideProfile,
  show2dLineDrawing,
  showSwitchToAttachedFormDialog,
  isFormsAndUsersTabCollapsed,
  show360View,
  show3dModel,
  showBentleyModel,
  isGalleryExpansionEnabled,
  isDetectText,
  showStructureModel,
  showBentleyAnalyticsReport,
  isAutodeskViewerEnabled,
  isVideoCallsEnabled,
  showGridViewButton,
  isRecordingEnabled,
  showVideobridgeRegionDropdown,
  isChatEnabled,
  isChatTranslationEnabled,
  isChatSpeechToTextEnabled,
  isBulkCopyEnabled,
  showMenuAnalytics,
  showAdvancedFilterButton,
  isInContentReviewEnabled,
  isCreatePublishFormRestrictionEnabled,
  isLocalRecordingEnabled,
  isCloudRecordingEnabled,
  isPageFolderAuthorizationEnabled,
  mobilePrimaryColor,
  isAdvancedFormSettingsEnabled,
  iterationDeploymentLifetimeInHours,
  isSessionReviewingEnabled,
  isMergeForReviewEnabled,
  isClassificationAnnotationsCreationEnabled,
  showTimeZoneOnUploadAnswers,
  isSpreadsheetsImportEnabled,
  contentCreationRestricted,
} from '../constants'

export const configFieldHelpText = {
  [isAdvancedInviteSettingsEnabled]: 'Show/hide advanced settings for invites',
  [isAdvancedFormSettingsEnabled]: 'Configure form permissions by session roles',
  [isContentMetadataEnabled]: 'Show/Hide "Content Metadata" button inside the session',
  [isSpreadsheetsImportEnabled]: 'Show/Hide "Import" button inside the Spreadsheets modal',
  [showTowerSideProfile]: 'Show/Hide "Tower Side Profile" button inside the session',
  [show2dLineDrawing]: 'Show/Hide "2D Line Drawing" button inside the session',
  [showSwitchToAttachedFormDialog]:
    'Enable/Disable "Switch to the attached form" pop-up in the session if "Attach Form" condition was triggred',
  [isFormsAndUsersTabCollapsed]: 'Collapse/Expand "Forms" and "Contact list" when opening the session',
  [show360View]: 'Show/Hide "360° View" button inside the session',
  [show3dModel]: 'Show/Hide "3D Model (Pix4D)" button inside the session',
  [showBentleyModel]: 'Show/Hide "3D Model (ContextCapture)" button inside the session',
  [isGalleryExpansionEnabled]: 'Enable/Disable expanded Gallery view in the session',
  [isDetectText]: 'Enable/Disable text detection in the session by Vicky AI',
  [isPageFolderAuthorizationEnabled]: 'Enable Session owner to give user rights for root session folders',
  [showStructureModel]: 'Show/Hide "Analytics Report" button inside the session',
  [showBentleyAnalyticsReport]: 'Show/Hide "Analytics Report (ContextCapture)" button inside the session',
  [isAutodeskViewerEnabled]: 'Show/Hide "CAD Model" button inside the session',
  [isVideoCallsEnabled]: 'Show/hide "Start call" button',
  [showGridViewButton]: 'Show/Hide "Grid View" button during the call in the session',
  [isRecordingEnabled]: 'Enable/Disable recording during the call in the session',
  [isLocalRecordingEnabled]: `Enable/Disable local recording during the call in the session. ${localRecordingDescription}`,
  [isCloudRecordingEnabled]: `Enable/Disable cloud recording during the call in the session. ${cloudRecordingDescription}`,
  [showVideobridgeRegionDropdown]: 'Show/Hide "Video chat region" selector in session settings',
  [isChatEnabled]: 'Show/hide Chat inside the session',
  [isChatTranslationEnabled]: 'Enable/Disable chat translation in the session',
  [isChatSpeechToTextEnabled]: 'Enable/Disable Speech to Text for audio chat messages in the session',
  [isBulkCopyEnabled]: 'Show/Hide "Copy to all users" option in the session tile',
  [showMenuAnalytics]: 'Show/Hide "Analytics" option in the "Dashboard"',
  [showAdvancedFilterButton]: 'Show/Hide "Advanced Filters" on the "All Sessions" page',
  [isInContentReviewEnabled]: 'Enable/Disable "In Session Review" feature',
  [isCreatePublishFormRestrictionEnabled]:
    'Show/hide “Create”, “Copy”, “Publish” buttons for any user but Tenant, Billing and System admins',
  [mobilePrimaryColor]: 'Affects the color of buttons, elements, headers, etc',
  [iterationDeploymentLifetimeInHours]:
    'Determines how many hours a deployed iteration will be running until it is stopped. Optional. Default: 12 hours.',
  [isSessionReviewingEnabled]: 'Show/Hide "For Review" button and "Review in new session" option inside the session',
  [isMergeForReviewEnabled]: 'Show/Hide "For Review" button and "Review in existing session" option inside the session',
  [isClassificationAnnotationsCreationEnabled]: 'Show/Hide "Classification Annotations" button inside the session.',
  [showTimeZoneOnUploadAnswers]:
    'Show/Hide the "Select Time Zone" dropdown to set the preferred time zone for date/time answers during answer upload.',
  [contentCreationRestricted]:
    'When this option is turned ON only System, Tenant, Session and Billing Admin can create sessions',
}

export const dependentFieldDisabilityExplanation = {
  [isChatEnabled]: 'Please enable Chat to change this value',
}
